<template>
  <div id="givenchygentleman">
      <div class="container bg-white">
          <div class="row text-center">
              <div class="col-12 p-4 mt-5">
                  <img class="img-fluid" src="../assets/images/givenchy/gentleman/logo-givenchy.jpg" alt="">
              </div>
              <div class="col-12 p-4">
                  <img class="img-fluid" src="../assets/images/givenchy/gentleman/strategie-edpd.jpg" alt="">
              </div>
              <div class="col-12 p-4">
                  <img class="img-fluid" src="../assets/images/givenchy/gentleman/logo-gentleman.jpg" alt="">
              </div>
          </div>
          <div class="row">
              <div class="col-4 mb-30" v-for="task in instagram" :key="task.titre" v-on:click="lien(task.video, task.lien)">
                  <img class="img-fluid" :src="task.lien" alt="">
              </div>
              <div v-show="showModal" @click="closeModale">
                  <transition name="modal">
                      <div class="modal-mask">
                          <div class="modal-wrapper">
                              <div class="modal-dialog">
                                  <!-- video element -->
                                  <plyr v-show="showVideo!=''" ref="plyr" class="plyr--video" :options="playerOptions">
                                      <video crossorigin autoplay/>
                                  </plyr>

                                  <img class="img-fluid" :src="showImg" alt="">
                              </div>
                          </div>
                      </div>
                  </transition>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    import plyr from 'vue-plyr';
    import 'plyr/dist/plyr.css';

    export default {
        components: {
            plyr,
        },
        data() {
            return {
                playerOptions: {
                    controls: []
                },
                showModal: false,
                showImg: '',
                showVideo: '',
                instagram: [
                    {
                        lien: require('../assets/images/givenchy/gentleman/img0.jpg'),
                        video: '/media/givenchy/gentleman/img0.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img1.jpg'),
                        video: '/media/givenchy/gentleman/img1.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img2.jpg'),
                        video: '/media/givenchy/gentleman/img2.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img3.jpg'),
                        video: '/media/givenchy/gentleman/img3.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img4.jpg'),
                        video: '/media/givenchy/gentleman/img4.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img5.jpg'),
                        video: '/media/givenchy/gentleman/img5.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img6.jpg'),
                        video: '/media/givenchy/gentleman/img6.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img7.jpg'),
                        video: '/media/givenchy/gentleman/img7.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img8.jpg'),
                        video: '/media/givenchy/gentleman/img8.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img9.jpg'),
                        video: '/media/givenchy/gentleman/img9.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img10.jpg'),
                        video: '/media/givenchy/gentleman/img10.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img11.jpg'),
                        video: '/media/givenchy/gentleman/img11.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img12.jpg'),
                        video: '/media/givenchy/gentleman/img12.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img13.jpg'),
                        video: '/media/givenchy/gentleman/img13.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img14.jpg'),
                        video: '/media/givenchy/gentleman/img14.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img15.jpg'),
                        video: '/media/givenchy/gentleman/img15.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img16.jpg'),
                        video: '/media/givenchy/gentleman/img16.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img17.jpg'),
                        video: '/media/givenchy/gentleman/img17.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img18.jpg'),
                        video: ''
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img19.jpg'),
                        video: ''
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img20.jpg'),
                        video: ''
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img21.jpg'),
                        video: '/media/givenchy/gentleman/img21.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img22.jpg'),
                        video: '/media/givenchy/gentleman/img22.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img23.jpg'),
                        video: '/media/givenchy/gentleman/img23.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img24.jpg'),
                        video: '/media/givenchy/gentleman/img24.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img25.jpg'),
                        video: '/media/givenchy/gentleman/img25.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img26.jpg'),
                        video: '/media/givenchy/gentleman/img26.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img27.jpg'),
                        video: ''
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img28.jpg'),
                        video: '/media/givenchy/gentleman/img28.mp4'
                    },
                    {
                        lien: require('../assets/images/givenchy/gentleman/img29.jpg'),
                        video: ''
                    },
                ]
            }
        },
        mounted () {
            // détection fin de lecture
            this.$refs.plyr.player.on('ended', event => {
                const instance = event.detail.plyr;
                console.log('ended: '+instance);

                this.showModal=false;
            });

            // debug
            this.$refs.plyr.player.on('debug', event => {
                const instance = event.detail.plyr;
                console.log('debug: '+instance);
            });

        },
        methods: {
            lien: function (event1,event2) {
                this.showVideo=event1;
                this.showImg=event2;
                this.showModal=true;

                // source url
                if (event1!='') {
                    this.$refs.plyr.player.source = {
                        type: 'video',
                        sources: [
                            {
                                src: event1,
                                type: 'video/mp4',
                            }
                        ]
                    };
                }
            },
            closeModale: function () {
                this.$refs.plyr.player.stop();
                this.showModal=false
            }
        },
    }
</script>

<style lang="scss" scoped>
    #givenchygentleman{
        padding-top: 100px;
        color: black;
    }

    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .plyr--video{
        position: absolute;
    }

    .mb-30 {
        margin-bottom: 30px;
    }
</style>
